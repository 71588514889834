import { fetchWrapper, IBaseResponse } from './apiBase';
import { BASE_API_URL } from '@/config/environment';

export interface IConfig {
  merge_requests_enabled: boolean;
  compare_enabled: boolean;
  schemas_tab_enabled: boolean;
  data_frame_query_enabled: boolean;
  file_upload_enabled: boolean;
  community_enabled: boolean;
  aws_role_configured: boolean;
  remote_staging_dataframe_enabled: boolean;
  workspaces_enabled: boolean;
  evaluations_enabled: boolean;
  external_storage_import_enabled: boolean;
  can_assign_featured_collections: boolean;
  help_pane_enabled: boolean;
}
export interface IConfigResponse extends IBaseResponse {
  config: IConfig;
}

export async function fetchConfig(token?: string): Promise<IConfigResponse> {
  const targetUrl = `${BASE_API_URL}/config`;

  return await fetchWrapper({
    resourceName: 'config',
    targetUrl,
    authToken: token,
    next: { revalidate: 10 },
  });
}
